/* Dashboard.css */
/* Define a light theme */


:root.light-theme {
  --background-color: rgb(255, 255, 255);
  --text-color: black;
  --card-background-color: #f2f2f2;
  --card-text-color: black;
  --card-border-color: #4e4e4e;
  --input-background-color: white;
  --input-text-color: rgb(255, 255, 255);
  --dragging-background-color: #ADD8E6; 
  --selected-border-color:  #0852ff; 
  --selected-box-shadow: 0 0 10px #0852ff; 
  --section-background-color: #ceeaf3;
}


/* Define a dark theme */
:root.dark-theme {
  --background-color: rgb(28, 27, 27);
  --text-color: white;
  --card-background-color: rgb(28, 27, 27);
  --card-text-color: white;
  --card-border-color: #ffffff;
  --input-background-color: rgb(82, 82, 82);
  --dragging-background-color: #191970;  /* Midnight Blue */
  --input-text-color: rgb(255, 255, 255);
  --selected-border-color: 2px solid rgb(119, 119, 238);
  --selected-box-shadow: 0 0 10px rgb(119, 119, 238);;
  --section-background-color: #23529ae4;

  /* Add as many properties as needed for your design */
}

.section-container {
  position: relative;
  margin-bottom: 2rem;
  padding: 2rem;
  background-color: var(--section-background-color);
}

.dashboard-container,
.dashboard-darkmode-switch,
.dashboard-section-container,
.dashboard-input,
.dashboard-section-title

{
  transition: all 0.1s ease;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
}

.container {
    background-color: black;
    color: white;
  }
  .section {
    margin-top: 2rem;
  }
  
  .form {
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }
  
  .textfield {
    margin-right: 1rem;
  }
  
  .card.light-theme {
    background-color:var(--section-background-color);
    color: black;
  }

  .collapse-button {
    position: absolute;
    top: 0;
    right: 0;  
  }
  
  
  .card.dark-theme {
    background-color:var(--section-background-color);
    color: white;
  }
  
  .card.is-dragging {
    background-color: var(--dragging-background-color);
}
  
  .card.is-selected {
    border: var(--selected-border-color);
    box-shadow: var(--selected-box-shadow)
  }
  
  .dashboard-container {
    padding: 20px;
    margin-bottom: 100vh;  /* adjust as needed */

  }

  
  .dashboard-section-title {
    margin-top: 30px;
    padding: 1rem;
    /* center it */
    display: flex;
    justify-content: center;
    cursor:pointer
  }
  
  .dashboard-form {
    padding: 0.2rem;
    margin-top: 0.5rem;
    margin-right: 0rem;    
  } /* idk about this one since it was suggested by copilot*/


  .dashboard-container {
    padding: 20px;
  }

  
  .dashboard-darkmode-switch {
    display: flex;
    justify-content: flex-end;
    padding: 0rem;
  }

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
  }
  
  .dashboard-section-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
  }
  

.dashboard-form {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px; /* Added to provide space after the form */
  }

  .dashboard-input {
    margin-right: 10px;
    flex-grow: 1; /* Added to make the input field take all available space */
  }

  .dashboard-button {
    flex-shrink: 0;  /* Prevent the button from shrinking if the text field is too long */
    margin-left: 10px; /* Added to provide space between the button and the input field */
  }

    .card {
    padding: 0.2rem;
    margin-bottom: 0.5rem;
    margin-right: 10px; /* Added to provide space on the right of the card */

  }

  .paper {
    background-color: var(--card-background-color);
    color: var(--card-text-color);
  }
  
  